.cont-featured{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 100;
    position: relative;
}

.cont-Cards-featured{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    padding: 0px;
    width: 100%;
}