@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.cont-navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3a7bd53a;
    width: 100%;
    padding: 1em 0;
    border-bottom: 1px solid #3a7bd5;
}
.cont-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cont-buttons ul {
    list-style: none;
    display: flex;
    width: 100%;
}


.cont-buttons ul li {
    /* display: flex; */
    width: 100%;
    cursor: pointer;
}

.nav-button {
    display: flex;
    padding: 12px 15px;
    border: 1px solid #3a7bd5;
    border-radius: 4px;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    color: black;
    z-index: 1;
    background-color: white;
    margin: 0 auto;
    z-index: 10;
    text-decoration: none;
    justify-content: center;
    width: 60%;
    align-items: center;
}

.nav-button:before {
    content: "";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%) scaleY(1) scaleX(1.25);
            transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.nav-button:after {
    content: "";
    position: absolute;
    left: 55%;
    -webkit-transform: translateX(-50%) scaleY(1) scaleX(1.45);
            transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #00d2ff;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.nav-button:hover {
    color: #ffffff;
    border: 0px solid #3a7bd5;
    /* border: 0px solid #39bda7; */
}

.nav-button:hover:before {
    top: -35%;
    background-color: #3a7bd5;
    -webkit-transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
            transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.nav-button:hover:after {
    top: -45%;
    background-color: #3a7bd5;
    -webkit-transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
            transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}


.cont-logo{
    width: 50%;
}

.cont-logo img{
    max-height: 60px;
    max-width: 100px;
    border-radius: 10px;
    transition: all 0.2s;
}

.cont-logo img:hover{
    border-radius: 10%;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}
.sectionOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(150deg ,#3451ED, #00d2ff);
    /* background-clip: url("../../../Img/backgrounds/Home-bg1.jpg"); */
    padding-bottom: 100px;
    width: 100%;
}

.svgWave{
    margin-top: -19%;
}
/* 
.sectionHero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 60%;
} */













.cont-featured{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 100;
    position: relative;
}

.cont-Cards-featured{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    padding: 0px;
    width: 100%;
}
.textTittle-h2{
    font-family: "Poppins","Montzerrat";
}
.grid-card{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.custom-card{
    margin: 0.5rem;
}

.custom-bg-opacity{
    background-color: rgba(0, 0, 0, 0.651)
}

.custom-transform{
    transition: all 0.3s;
    -webkit-transform: scale(100%);
            transform: scale(100%);
}
.custom-transform:hover{
    -webkit-transform: scale(102%);
            transform: scale(102%);
}

@media screen and (max-width: 1024px) {
    .grid-card{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .grid-card{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 615px) {
    .grid-card{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

.carousel {
  margin-bottom: 4rem;
}

.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

.carousel-item {
  height: 32rem;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}

@media (min-width: 40em) {
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}

.contPageRegistry{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cont-fromRegistry{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formRegistry{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1rem 2rem;
    border-radius: 0.5em;
    width: 100%;
}

.formRegistry label {
    margin-top: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.2em;
}

.formRegistry input{
    border-radius: 1rem 0.5em;
    border-style: none;
    padding: 1rem 1rem 1rem;
}

.formRegistry button {
    margin-top: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: red;
    border-radius: 1rem 0.5rem;
    border-style: none;
    font-weight: bold;
    font-size: 16px
}
.contPageLogin{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.contFormLogin{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formLogin{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1rem 2rem;
    border-radius: 0.5em;
    width: 100%;
}
.formLogin label {
    margin-top: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.2em;
}
.formLogin input{
    border-radius: 1rem 0.5em;
    border-style: none;
    padding: 1rem 1rem 1rem;
}
.formLogin button {
    margin-top: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: red;
    border-radius: 1rem 0.5rem;
    border-style: none;
    font-weight: bold;
    font-size: 16px
}
.cont-formNewAnounce{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formNewAnounce{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1rem 2rem;
    border-radius: 0.5em;
    width: 100%;
}

.formNewAnounce label {
    margin-top: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.2em;
}

.formNewAnounce input{
    border-radius: 1rem 0.5em;
    border-style: none;
    padding: 1rem 1rem 1rem;
}

.formNewAnounce button {
    margin-top: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: red;
    border-radius: 1rem 0.5rem;
    border-style: none;
    font-weight: bold;
    font-size: 16px
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}

.list-group {
  width: auto;
  max-width: 460px;
  margin: 4rem auto;
}

.form-check-input:checked + .form-checked-content {
  opacity: 0.5;
}

.form-check-input-placeholder {
  pointer-events: none;
  border-style: dashed;
}
[contenteditable]:focus {
  outline: 0;
}

.list-group-checkable {
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 0;
}
.list-group-checkable .list-group-item {
  cursor: pointer;
  border-radius: 0.5rem;
}
.list-group-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.list-group-item-check:hover + .list-group-item {
  background-color: var(--bs-light);
}
.list-group-item-check:checked + .list-group-item {
  color: #fff;
  background-color: var(--bs-blue);
}
.list-group-item-check[disabled] + .list-group-item,
.list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5;
}



/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
}

.cover-container {
  max-width: 42em;
}

.cont-fromRegistry{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formRegistry{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1rem 2rem;
    border-radius: 0.5em;
    width: 100%;
}

.formRegistry label {
    margin-top: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.2em;
}

.formRegistry input{
    border-radius: 1rem 0.5em;
    border-style: none;
    padding: 1rem 1rem 1rem;
}

.formRegistry button {
    margin-top: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: red;
    border-radius: 1rem 0.5rem;
    border-style: none;
    font-weight: bold;
    font-size: 16px
}

