.cont-buttons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cont-buttons ul {
    list-style: none;
    display: flex;
    width: 100%;
}


.cont-buttons ul li {
    /* display: flex; */
    width: 100%;
    cursor: pointer;
}

.nav-button {
    display: flex;
    padding: 12px 15px;
    border: 1px solid #3a7bd5;
    border-radius: 4px;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    color: black;
    z-index: 1;
    background-color: white;
    margin: 0 auto;
    z-index: 10;
    text-decoration: none;
    justify-content: center;
    width: 60%;
    align-items: center;
}

.nav-button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.nav-button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #00d2ff;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.nav-button:hover {
    color: #ffffff;
    border: 0px solid #3a7bd5;
    /* border: 0px solid #39bda7; */
}

.nav-button:hover:before {
    top: -35%;
    background-color: #3a7bd5;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.nav-button:hover:after {
    top: -45%;
    background-color: #3a7bd5;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

