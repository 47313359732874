.cont-fromRegistry{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.formRegistry{
    background-color: aquamarine;
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem 1rem 2rem;
    border-radius: 0.5em;
    width: 100%;
}

.formRegistry label {
    margin-top: 1rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.2em;
}

.formRegistry input{
    border-radius: 1rem 0.5em;
    border-style: none;
    padding: 1rem 1rem 1rem;
}

.formRegistry button {
    margin-top: 1rem;
    padding: 1rem 0rem 1rem 0rem;
    background-color: red;
    border-radius: 1rem 0.5rem;
    border-style: none;
    font-weight: bold;
    font-size: 16px
}