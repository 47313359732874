.cont-logo{
    width: 50%;
}

.cont-logo img{
    max-height: 60px;
    max-width: 100px;
    border-radius: 10px;
    transition: all 0.2s;
}

.cont-logo img:hover{
    border-radius: 10%;
    transform: translateY(-2px);
}